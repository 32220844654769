import * as React from 'react'
import Contact from '../components/contact'
import Features from '../components/features'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'
import Cta from '../components/cta'
import Services from '../components/services'
import TrustPilot from '../components/trustPilot'

const Index = () => {
  const query = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
          title
        }
      }
    }
  `)

  const siteMetadata = query.site.siteMetadata

  return (
    <>
      <Seo
        pageTitle={siteMetadata.description}
        description={siteMetadata.description}
      />

      <Layout>
        <main>
          <Hero />
          <Features />
          <Cta />
          <Services />
          <TrustPilot />
          <Contact />
        </main>
      </Layout>
    </>
  )
}

export default Index
