import { Link } from 'gatsby'
import * as React from 'react'

const Services = () => {
  return (
    <section className='bg-white py-5 container-fluid' id='services'>
      <div className='row justify-content-center text-center'>
        <div className='col-md-4 col-sm-10'>
          <h4 className='my-2'>Wills</h4>
          <p className='text-muted'>
            Do not let the Government determine where your estate and children
            go
          </p>

          <Link to={'/wills'}>
            <button className='btn-lg btn-secondary shadow-sm my-3'>
              Wills
            </button>
          </Link>
        </div>

        <div className='col-md-4 col-sm-10'>
          <h4 className='my-2'>Trusts</h4>
          <p className='text-muted'>
            • 18 – 25 Trust
            <br />• Discretionary Trusts
            <br />• Vulnerable Persons Trust
          </p>

          <Link to={'/trusts'}>
            <button className='btn-lg btn-secondary shadow-sm my-3'>
              Trusts
            </button>
          </Link>
        </div>

        <div className='col-md-4 col-sm-10'>
          <h4 className='my-2'>Lasting Power of Attorney</h4>
          <p className='text-muted'>
            If you were unable to make your own decisions due to losing mental
            capacity or even due to ill-health or of an accident – what would
            happen?
          </p>

          <Link to={'/lasting-power-of-attorney'}>
            <button className='btn-lg btn-secondary shadow-sm my-3'>
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Services
