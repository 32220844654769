import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Hero = () => {
  return (
    <div style={{ display: 'grid' }}>
      <StaticImage
        style={{
          gridArea: '1/1',
          height: 'calc(100vh - 4rem)'
        }}
        layout='fullWidth'
        alt=''
        src={'../images/hero-bg.jpg'}
        formats={['auto', 'webp', 'avif']}
        placeholder={'blurred'}
      />
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
          backgroundColor: 'rgba(23, 49, 82,0.8)'
        }}
      >
        <div
          className='col-md-10 col-lg-7 p-3 align-self-center'
          id='hero-text'
        >
          <h1>
            The idea is not to live forever, but to create something that{' '}
            <span style={{ fontWeight: '400' }}>Will</span>.
          </h1>
          <Link to={'#services'}>
            <button className='btn-lg btn-secondary shadow-lg'>
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
