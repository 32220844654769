import * as React from 'react'
import { Helmet } from 'react-helmet'

const TrustPilot = () => {
  return (
    <>
      <Helmet>
        <script
          type='text/javascript'
          src='https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
          async
        ></script>
      </Helmet>
      <div
        className='trustpilot-widget py-5'
        data-locale='en-GB'
        data-template-id='5419b6a8b0d04a076446a9ad'
        data-businessunit-id='5c92bc4b7a437100018d1d6a'
        data-style-height='100px'
        data-style-width='100%'
        data-theme='light'
      >
        <a
          href='https://uk.trustpilot.com/review/willcentre.co.uk'
          target='_blank'
          rel='noreferrer'
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}

export default TrustPilot
