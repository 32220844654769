import { Link } from 'gatsby'
import * as React from 'react'

const Cta = () => {
  return (
    <section className='bg-grey py-5 container-fluid bg-primary' id='cta'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <h1 className='display-4'>Schedule a Callback</h1>
          <h4 className='my-3 text-muted'>
            Schedule a callback now and we'll get back to you as soon as
            possible.
          </h4>
        </div>
        <div className='col-md-auto text-center'>
          <Link to={'#contact'}>
            <button className='btn-lg btn-outline-primary shadow mt-3'>
              Schedule Call
            </button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Cta
