import * as React from 'react'
import { Link } from 'gatsby'

const Features = () => {
  return (
    <section className='bg-white py-5 container-fluid' id='features'>
      <div className='row justify-content-center text-center'>
        <div className='col-sm-10 col-md-4 col-lg-3'>
          <h4 className='my-2'>Who</h4>
          <p className='text-muted'>
            The Will Centre works up and down the country talking to individuals
            providing them with a sense of security and breathes everlasting
            life into the hearts of people, families, loved ones for generations
            to come.
          </p>

          <Link to={'/about-us'}>
            <button className='btn-lg btn-secondary my-3 shadow-sm'>
              About Us
            </button>
          </Link>
        </div>

        <div className='col-sm-10 col-md-4 col-lg-3'>
          <h4 className='my-2'>How</h4>
          <p className='text-muted'>
            Contact Us & we will simply arrange a consultation which suits you.
            Whether you would like a face to face visit at the comfort of your
            own home or a video call, we will work at your convenience.
          </p>

          <Link to={'/contact'}>
            <button className='btn-lg btn-secondary my-3 shadow-sm'>
              Get in touch
            </button>
          </Link>
        </div>

        {/* <div className='col-sm-10 col-md-4 col-lg-3'>
          <h4 className='my-2'>Why</h4>
          <p className='text-muted'>
            Simple! Have a read of what people have to say and why they have
            chosen us.
          </p>

          <Link to={'/'}>
            <button className='btn-lg btn-secondary my-3 shadow-sm'>
              Link
            </button>
          </Link>
        </div> */}
      </div>
    </section>
  )
}

export default Features
